import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'
import ScrollReveal from 'scrollreveal'
import Cookie from 'js.cookie';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    //trailer-table-container scrolled left as default
    $('.trailer-table-container').each(function() {
      $(this).scrollLeft($(this).find('table').width());
    });

    //animate table scroll
    function tableScroll (el) {
      $(el).animate({
        scrollLeft: - $(el).find('table').width(),
      }, 3000);
    }

    //animate tableScroll after scrollReveal
    ScrollReveal().reveal('.trailer-table-container', {
      mobile: true,
      distance: 0,
      duration: 200,
      delay: 100,
      opacity: 1,
      viewFactor: 0.8,
      afterReveal: tableScroll,
    });

    // desktop animations config
    var desktop = {
      mobile: false,
      distance: '5%',
      duration: 1500,
      viewFactor: 0.3,
      delay: 100,
      origin: 'bottom',
    }

    ScrollReveal(desktop);

    ScrollReveal().reveal('#why-choose, body.home .testimonials-panel');

    ScrollReveal().reveal('body.home header nav, .service-row:nth-of-type(odd) .content', {
      origin: 'right',
    });

    ScrollReveal().reveal('.hero-content, .service-row:nth-of-type(even) .content', {
      origin: 'left',
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    $('img').parent('a').addClass('no-icon');
    $('i').parent('a').addClass('no-icon');

    //sub-menu min-width equals parent's width
    $('li.menu-item-has-children .sub-menu').each(function() {
      $(this).css('min-width', $(this).parent().width() + 60);
    });

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });
  
    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });
  
    //sidenav dropdown on click
    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });
    
    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    $('.project-gallery').each(function() {
      lightGallery(this, {
        licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
        selector: '.gallery-image, .gallery-trigger',
        plugins: [lgZoom, lgThumbnail, fullScreen],
        speed: 500,
        fullScreen: true,
        subHtmlSelectorRelative: true,
        mobileSettings: {
          controls: true,
          showCloseIcon: true,
          download: false,
        },
      });
    });
  },
};
